import { AuthService, AuthResponseData } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  isLoading = false;
  error = null;
  testSite = false;
  password1 = '';
  password2 = '';

  
  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    // are we logged in?
    this.testSite = environment.testSite;
    const user = this.authService.user.getValue();
    this.isLoading = this.authService.fetching.getValue();
    // try this..
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    if (user) {
      if (this.authService.redirect) {
        this.router.navigateByUrl(this.authService.redirect);
      } else {
        this.router.navigate(['/']);
      }
    }
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = this.password1;
    const firstName = form.value.firstName;
    const lastName = form.value.lastName;

    this.isLoading = true;

    this.authService.register(email, password, firstName, lastName)
    .subscribe(
      (resData) => {
        this.isLoading = false;
        // do they need to evaluate?
        this.router.navigate(['/']);
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }

  checkPassword(password:string, check:string) {
    switch (check) {
      case 'upper':
        return /[A-Z]/.test(password);
        break;
      case 'lower':
        return /[a-z]/.test(password);
        break;
      case 'num':
        return /[0-9]/.test(password);
        break;
      case 'special':
        return /[$@$!%*?&]/.test(password);
        break;
    }
    return false;
  }

}
